var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ViewLayout',{scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("useLabels")('Brands'))+" ")]},proxy:true},{key:"header-caption",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("useLabels")('Add and manage brands'))+" ")]},proxy:true},{key:"content",fn:function(){return [_c('FilterBar',{attrs:{"search-query":_vm.searchQuery,"search-query-label":"Brand Name","hide-brand-filter":""},on:{"update:searchQuery":function($event){_vm.searchQuery=$event},"update:search-query":function($event){_vm.searchQuery=$event}},scopedSlots:_vm._u([{key:"action",fn:function(){return [(!_vm.readOnly)?_c('PrimaryActionButton',{attrs:{"disabled":!_vm.isMultiBrand,"tooltip-text":!_vm.isMultiBrand
						? 'Multi-brand functionality must be enabled to use this feature'
						: undefined},on:{"click":_vm.addBrand}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create ")],1):_vm._e()]},proxy:true}])}),(_vm.enableLabels)?_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s('Brand')+" Label ")]},proxy:true},{key:"body",fn:function(){return [_c('ValidationForm',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"d-flex cassie-horizontal-md"},[_c('TextField',{staticClass:"cassie-input-width-md",attrs:{"disabled":_vm.readOnly,"label":'Brand Label in Admin Portal *',"rules":"required"},model:{value:(_vm.brandLabel),callback:function ($$v) {_vm.brandLabel=$$v},expression:"brandLabel"}}),(!_vm.readOnly)?_c('PrimaryActionButton',{on:{"click":function($event){return handleSubmit(_vm.SubmitBrandLabel)}}},[_vm._v(" Save ")]):_vm._e()],1)]}}],null,false,1706843468)})]},proxy:true}],null,false,3836209539)}):_vm._e(),_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("useLabels")('Brands'))+" ")]},proxy:true},{key:"body",fn:function(){return [_c('DataTable',{attrs:{"items":_vm.tableData,"headers":_vm.tableHeaders,"sort-by":"brandId","sort-desc":""},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
						var item = ref.item;
return [_c('IconButton',{attrs:{"tooltip-text":_vm._f("useLabels")('Open Brand')},on:{"click":function($event){_vm.brand = JSON.parse(JSON.stringify(item))}}},[_vm._v(" "+_vm._s(_vm.readOnly ? 'mdi-eye' : 'mdi-pencil')+" ")])]}}])})]},proxy:true}])}),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var handleSubmit = ref.handleSubmit;
return [(_vm.brand)?_c('Modal',{attrs:{"width":"600px"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.showAddModal ? 'Add' : 'Edit')+" "+_vm._s(_vm._f("useLabels")('Brand'))+" ")]},proxy:true},{key:"modal-content",fn:function(){return [_c('div',{staticClass:"cassie-vertical-md"},[_c('TextField',{attrs:{"disabled":_vm.readOnly,"label":_vm._f("useLabels")('Brand Name *'),"rules":{required: true, max: 255}},model:{value:(_vm.brand.brandName),callback:function ($$v) {_vm.$set(_vm.brand, "brandName", $$v)},expression:"brand.brandName"}}),_c('TextField',{attrs:{"disabled":_vm.readOnly,"label":"Description","rules":{max: 2048}},model:{value:(_vm.brand.description),callback:function ($$v) {_vm.$set(_vm.brand, "description", $$v)},expression:"brand.description"}}),_c('TextField',{attrs:{"disabled":_vm.readOnly,"rules":{isUrl: true, max: 255},"label":"Public Url"},model:{value:(_vm.brand.publicUrl),callback:function ($$v) {_vm.$set(_vm.brand, "publicUrl", $$v)},expression:"brand.publicUrl"}})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('SecondaryActionButton',{on:{"click":_vm.closeSubmitModal}},[_vm._v(" "+_vm._s(_vm.readOnly ? 'Close' : 'Cancel')+" ")]),(!_vm.readOnly)?_c('PrimaryActionButton',{on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.showAddModal ? 'Create' : 'Save')+" ")]):_vm._e()]},proxy:true}],null,true)}):_vm._e()]}}])}),(_vm.brandToRemove)?_c('ConfirmDeleteModal',{attrs:{"entity-name":_vm.brandToRemove.brandName,"entity-type":"Brand"},on:{"close":function($event){_vm.brandToRemove = null},"delete":_vm.deleteBrand}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }