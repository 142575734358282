<template>
	<ViewLayout>
		<template #header-title>
			{{ 'Brands' | useLabels }}
		</template>
		<template #header-caption>
			{{ 'Add and manage brands' | useLabels }}
		</template>
		<template #content>
			<FilterBar
				:search-query.sync="searchQuery"
				search-query-label="Brand Name"
				hide-brand-filter
			>
				<template #action>
					<PrimaryActionButton
						v-if="!readOnly"
						:disabled="!isMultiBrand"
						:tooltip-text="!isMultiBrand
							? 'Multi-brand functionality must be enabled to use this feature'
							: undefined"
						@click="addBrand"
					>
						<v-icon left>
							mdi-plus
						</v-icon>
						Create
					</PrimaryActionButton>
				</template>
			</FilterBar>
			<SectionCard v-if="enableLabels">
				<template #title>
					{{ 'Brand' }} Label
				</template>
				<template #body>
					<ValidationForm

						#default="{ handleSubmit }"
					>
						<div class="d-flex cassie-horizontal-md">
							<TextField
								v-model="brandLabel"
								:disabled="readOnly"
								:label="'Brand Label in Admin Portal *'"
								rules="required"
								class="cassie-input-width-md"
							/>
							<PrimaryActionButton
								v-if="!readOnly"
								@click="handleSubmit(SubmitBrandLabel)"
							>
								Save
							</PrimaryActionButton>
						</div>
					</ValidationForm>
				</template>
			</SectionCard>
			<SectionCard>
				<template #title>
					{{ 'Brands' | useLabels }}
				</template>
				<template #body>
					<DataTable
						:items="tableData"
						:headers="tableHeaders"
						sort-by="brandId"
						sort-desc
						@click:row="rowClick"
					>
						<template #item.action="{ item }">
							<IconButton
								:tooltip-text="'Open Brand' | useLabels"
								@click="brand = JSON.parse(JSON.stringify(item))"
							>
								{{ readOnly ? 'mdi-eye' : 'mdi-pencil' }}
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<validation-observer #default="{ handleSubmit }">
				<Modal
					v-if="brand"
					width="600px"
				>
					<template #modal-title>
						{{ showAddModal ? 'Add' : 'Edit' }} {{ 'Brand' | useLabels }}
					</template>
					<template #modal-content>
						<div class="cassie-vertical-md">
							<TextField
								v-model="brand.brandName"
								:disabled="readOnly"
								:label="'Brand Name *' | useLabels"
								:rules="{required: true, max: 255}"
							/>
							<TextField
								v-model="brand.description"
								:disabled="readOnly"
								label="Description"
								:rules="{max: 2048}"
							/>
							<TextField
								v-model="brand.publicUrl"
								:disabled="readOnly"
								:rules="{isUrl: true, max: 255}"
								label="Public Url"
							/>
						</div>
					</template>
					<template #modal-footer>
						<SecondaryActionButton @click="closeSubmitModal">
							{{ readOnly ? 'Close' : 'Cancel' }}
						</SecondaryActionButton>
						<PrimaryActionButton
							v-if="!readOnly"
							@click="handleSubmit(submit)"
						>
							{{ showAddModal ? 'Create' : 'Save' }}
						</PrimaryActionButton>
					</template>
				</Modal>
			</validation-observer>
			<ConfirmDeleteModal
				v-if="brandToRemove"
				:entity-name="brandToRemove.brandName"
				entity-type="Brand"
				@close="brandToRemove = null"
				@delete="deleteBrand"
			/>
		</template>
	</ViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import Modal from '../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import TextField from '../../../../../shared/components/text-field.vue'
import { upsertBrandLabel, createBrand, updateBrand } from '../../../../../shared/utils/api/brands.js'
import { loadAllBrands, allBrandsState, isMultiBrand } from '../../../../../shared/state/brands.js'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { BRANDS_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'

const UNIVERSAL_BRAND_ID = 0

export default {
	components: { ValidationForm, TextField, SecondaryActionButton, Modal, ConfirmDeleteModal, DataTable, PrimaryActionButton, SectionCard, FilterBar, ViewLayout, IconButton },
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			searchQuery: null,
			showAddModal: false,
			brand: null,
			brandToRemove: null,
			enableLabels: true, // we have to create new endpoints to update brand labels.
			brands: [],
			brandLabel: useLabels('Brand'),
			UNIVERSAL_BRAND_ID
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		tableHeaders () {
			return [
				{ value: 'brandId', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand Name'), width: '15%' },
				{ value: 'description', text: 'Description', width: '55%' },
				{ value: 'createdDate', text: 'Created Date', width: '10%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		},
		tableData () {
			return this.brands?.filter(({ brandName }) => !this.searchQuery || brandName.toLowerCase().includes(this.searchQuery.toLowerCase()))
		},
		isEdit () {
			return Boolean(!this.showAddModal)
		},
		readOnly () {
			return !this.checkPermission(BRANDS_MODULE_FULL_PERMISSIONS)
		},
		isMultiBrand () {
			return isMultiBrand.value
		}
	},
	async created () {
		await this.loadBrands()
	},
	methods: {
		async loadBrands () {
			await loadAllBrands()
			this.brands = allBrandsState.value
		},
		addBrand () {
			this.showAddModal = true
			this.brand = {
				brandName: '',
				description: ''
			}
		},
		closeSubmitModal () {
			this.showAddModal = null
			this.brand = null
		},
		async submit () {
			if (!this.isEdit) {
				await createBrand(this.brand)
			} else {
				await updateBrand(this.brand)
			}
			showSnackbar(useLabels(`This Brand has been ${!this.isEdit ? 'created' : 'updated'}`))
			this.closeSubmitModal()
			await this.loadBrands()
		},
		async SubmitBrandLabel () {
			await upsertBrandLabel(this.brandLabel)
			showSnackbar(`The brand label ${this.brandLabel}  has been updated`)
			await this.loadBrands()
		},
		rowClick (row) {
			if (this.readOnly) {
				row.readOnly = true
			}
			this.brand = JSON.parse(JSON.stringify(row))
		}
	}
}
</script>
